import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { isLive, isCompleted } from '../utils/utils';
import { Page, SEO, ProgramCard } from '../components';
import {
  ProgramContainer,
  DayWrapper,
  DayHeader,
  ProgramList,
  ProgramItem,
  HighlightHeading,
  Sponsors,
  SponsorItem,
  SponsorGroupItem,
} from '../compositions/ProgramPage';
import { MainNavigation } from '../compositions';
import langeNachtLogo from '../images/menschen-bewegen-logo.png';
import unhcrLogo from '../images/unhcr-logo.png';
import kironLogoWhite from '../images/kiron-logo.svg';
import auswaertigesAmtLogo from '../images/auswaertiges-amt-logo.svg';

const Program = () => {
  const data = useStaticQuery(graphql`
    query allContentfulProgramEntriesQuery {
      nonSatelite: allContentfulPresentation(
        sort: { fields: date, order: ASC }
        filter: { isSatelite: { eq: false } }
      ) {
        edges {
          node {
            id
            slug
            date(formatString: "DD.MM.YYYY, h:mm A")
            presentationEnd(formatString: "DD.MM.YYYY, h:mm A")
            title
            shortDescription
            isSatelite
            tags
            presentationLink
            presenter {
              name
            }
            image {
              description
              fluid(maxWidth: 350, resizingBehavior: SCALE) {
                src
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
          }
        }
      }
      satelite: allContentfulPresentation(sort: { fields: date, order: ASC }, filter: { isSatelite: { eq: true } }) {
        edges {
          node {
            id
            slug
            date(formatString: "DD.MM.YYYY, h:mm A")
            presentationEnd(formatString: "DD.MM.YYYY, h:mm A")
            title
            shortDescription
            isSatelite
            tags
            presentationLink
            presenter {
              name
            }
            image {
              description
              fluid(maxWidth: 350, resizingBehavior: SCALE) {
                src
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
          }
        }
      }
    }
  `);

  /**
   * TODO: @danijelgrabez Annotate and extract
   */
  const nonSateliteGroups = data.nonSatelite.edges
    .filter(item => (process.env.NODE_ENV === 'development' ? item : !item.node.slug.includes('test-fpo-program')))
    .reduce((acc, value) => {
      const date = value.node.date.split(', ')[0];

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(value);

      return acc;
    }, {});

  const nonSateliteGroupArrayDays = Object.keys(nonSateliteGroups).map(date => {
    return {
      date,
      presentations: nonSateliteGroups[date],
    };
  });

  const [dayOneNonSatelite, dayTwoNonSatelite] = nonSateliteGroupArrayDays;

  const sateliteGroups = data.satelite.edges
    .filter(item => (process.env.NODE_ENV === 'development' ? item : !item.node.slug.includes('test-fpo-program')))
    .reduce((acc, value) => {
      const date = value.node.date.split(', ')[0];

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(value);

      return acc;
    }, {});

  const sateliteGroupArrayDays = Object.keys(sateliteGroups).map(date => {
    return {
      date,
      presentations: sateliteGroups[date],
    };
  });

  const [dayOneSatelite, dayTwoSatelite] = sateliteGroupArrayDays;

  return (
    <Page>
      <SEO title="Program | Virtual Refugee Conference" />
      <MainNavigation />
      <ProgramContainer>
        <HighlightHeading element="h2" colorKind="light">
          Virtual Refugee Conference 2020
        </HighlightHeading>
        <DayWrapper>
          <DayHeader
            title="Day 1: Digital Long Night of Ideas"
            organiser="by Kiron and UNHCR"
            time="19.06.2020, 10:00 AM CET"
            sponsors={
              <Sponsors>
                <SponsorItem>
                  <img src={auswaertigesAmtLogo} style={{ width: 120 }} />
                </SponsorItem>
                <SponsorItem>
                  <img src={langeNachtLogo} style={{ width: 90 }} />
                </SponsorItem>
                <SponsorGroupItem>
                  <strong>organized by:</strong>
                  <img src={kironLogoWhite} style={{ width: 70 }} />
                  <img src={unhcrLogo} style={{ height: 45 }} />
                </SponsorGroupItem>
              </Sponsors>
            }
          />
          <ProgramList>
            {dayOneNonSatelite.presentations.map(item => (
              <ProgramItem key={item.node.id}>
                <ProgramCard
                  title={item.node.title}
                  shortDescription={item.node.shortDescription}
                  tags={item.node.tags}
                  presenter={item.node.presenter}
                  date={item.node.date}
                  isLive={isLive(item.node.date)}
                  isCompleted={isCompleted(item.node.presentationEnd)}
                  isSatelite={item.node.isSatelite}
                  slug={item.node.slug}
                  image={item.node.image}
                  programStreamURL={item.node.presentationLink}
                />
              </ProgramItem>
            ))}
          </ProgramList>
        </DayWrapper>
        <DayWrapper>
          <DayHeader title="Day 2: Virtual Refugee Conference" time="20.06.2020, 12:30 PM CET" />
          <ProgramList>
            {dayTwoNonSatelite.presentations.map(item => (
              <ProgramItem key={item.node.id}>
                <ProgramCard
                  title={item.node.title}
                  shortDescription={item.node.shortDescription}
                  tags={item.node.tags}
                  presenter={item.node.presenter}
                  date={item.node.date}
                  isLive={isLive(item.node.date)}
                  isCompleted={isCompleted(item.node.presentationEnd)}
                  isSatelite={item.node.isSatelite}
                  slug={item.node.slug}
                  image={item.node.image}
                  programStreamURL={item.node.presentationLink}
                />
              </ProgramItem>
            ))}
          </ProgramList>
        </DayWrapper>
        <HighlightHeading element="h2" colorKind="light">
          Satelite Events
        </HighlightHeading>
        <DayWrapper>
          <DayHeader title="Day 1: Virtual Refugee Conference" time="19.06.2020, 01:00 PM CET" />
          <ProgramList>
            {dayOneSatelite.presentations.map(item => (
              <ProgramItem key={item.node.id}>
                <ProgramCard
                  title={item.node.title}
                  shortDescription={item.node.shortDescription}
                  tags={item.node.tags}
                  presenter={item.node.presenter}
                  date={item.node.date}
                  isLive={isLive(item.node.date)}
                  isCompleted={isCompleted(item.node.presentationEnd)}
                  isSatelite={item.node.isSatelite}
                  slug={item.node.slug}
                  image={item.node.image}
                  programStreamURL={item.node.presentationLink}
                />
              </ProgramItem>
            ))}
          </ProgramList>
        </DayWrapper>
        <DayWrapper>
          <DayHeader title="Day 2: Virtual Refugee Conference" time="20.06.2020, 10:30 AM CET" />
          <ProgramList>
            {dayTwoSatelite.presentations.map(item => (
              <ProgramItem key={item.node.id}>
                <ProgramCard
                  title={item.node.title}
                  shortDescription={item.node.shortDescription}
                  tags={item.node.tags}
                  presenter={item.node.presenter}
                  date={item.node.date}
                  isLive={isLive(item.node.date)}
                  isCompleted={isCompleted(item.node.presentationEnd)}
                  isSatelite={item.node.isSatelite}
                  slug={item.node.slug}
                  image={item.node.image}
                  programStreamURL={item.node.presentationLink}
                />
              </ProgramItem>
            ))}
          </ProgramList>
        </DayWrapper>
      </ProgramContainer>
    </Page>
  );
};

export default Program;
