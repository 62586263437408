import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { rem } from '../../../utils/utils';

const headerStyles = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
`;

const titleStyles = ({
  weight = 700,
  theme: {
    font: { size, color },
  },
}) => css`
  font-weight: ${weight};
  color: ${color.dark};
  margin: 0;
  font-size: ${rem(size.medium + 4)};
`;

const organiserStyles = ({
  theme: {
    font: { size },
  },
}) => css`
  font-weight: normal;
  font-size: ${rem(size.default + 2)};
`;

/**
 * Component style declarations
 */
export const Header = styled('header')(headerStyles);
export const Title = styled('h3')(titleStyles);
export const Organiser = styled('span')(organiserStyles);
