import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Container, Heading } from '../../components';
import { rem } from '../../utils/utils';

const programContainerStyles = css`
  padding-top: 80px;
  text-align: left;
`;

const highlightHeadingStyles = ({
  theme: {
    font: { color },
  },
}) => css`
  color: ${color.brand};
  margin-bottom: 15px;
  font-size: ${rem(40)};
`;

const dayWrapperStyles = css`
  margin-bottom: 120px;
`;

const sponsorsStyles = ({
  theme: {
    font: { color },
    grid: { breakpoint },
  },
}) => css`
  color: ${color.brand};
  display: grid;
  grid-template-columns: 120px 120px 1fr;
  grid-gap: 20px;
  max-width: 600px;
  list-style-type: none;
  padding: 0;
  margin-top: 30px;
  align-items: center;
  ${breakpoint.toPhoneMedium} {
    max-width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

const sponsorGroupItemStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: flex;
  align-items: center;
  ${breakpoint.toPhoneMedium} {
    grid-column: 1 / -1;
  }

  img {
    margin-left: 20px;
  }
`;

const programListStyles = css`
  list-style-type: none;
  padding: 0;
`;

const programItemStyles = css`
  &:not(:last-of-type) {
    margin-bottom: 60px;
  }
`;

/**
 * Component style declarations
 */
export const ProgramContainer = styled(Container)(programContainerStyles);
export const HighlightHeading = styled(Heading)(highlightHeadingStyles);
export const DayWrapper = styled('section')(dayWrapperStyles);
export const Sponsors = styled('div')(sponsorsStyles);
export const SponsorItem = styled('div')();
export const SponsorGroupItem = styled('div')(sponsorGroupItemStyles);
export const ProgramList = styled('ul')(programListStyles);
export const ProgramItem = styled('li')(programItemStyles);
