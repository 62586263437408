import React from 'react';
import PropTypes from 'prop-types';
import { Header, Title, Organiser } from './DayHeader.style';

const DayHeader = ({ title, organiser, time, sponsors }) => (
  <Header>
    <div>
      <Title>
        {title} <Organiser>{organiser}</Organiser>
      </Title>
      <Title weight={400}>{time}</Title>
      {sponsors}
    </div>
  </Header>
);

DayHeader.propTypes = {
  title: PropTypes.string.isRequired,
  organiser: PropTypes.string,
  time: PropTypes.string.isRequired,
  sponsors: PropTypes.node,
};

DayHeader.defaultProps = {
  sponsors: null,
  organiser: '',
};

export default DayHeader;
